import { defineLocalBusiness, defineOrganization } from "nuxt-schema-org/schema";

type SEO = {
  og_title: string;
  title: string;
  description: string;
  og_description: string;
  og_image: string;
  twitter_title: string;
  twitter_description: string;
  twitter_image: string;
};

type Story = {
  path?: string;
  full_slug?: string;
  name?: string;
};

export default function (seo: SEO, story: Story) {
  const metaTags: { name?: string; content: any; property?: string }[] = [];
  const config = useRuntimeConfig().public;
  const isTagNotEmpty = (value: String) => value?.trim().length > 0;

  const getTagValue = (...values: string[]) => values.find(isTagNotEmpty) || "";

  const tags = Object.assign({}, seo, {
    title: getTagValue(seo.title, seo.og_title, story.name || ""),
    description: getTagValue(seo.description, seo.og_description),
    "og:title": getTagValue(seo.og_title, seo.title, story.name || ""),
    og_title: getTagValue(seo.og_title, seo.title, story.name || ""),
    "og:description": getTagValue(seo.og_description, seo.description),
    og_description: getTagValue(seo.og_description, seo.description),
    "og:image": getTagValue(story?.content?.image?.filename, seo.og_image) || `${config.SITE_URL}/kudosity-social.jpg`,
    "twitter:title": getTagValue(seo.twitter_title, seo.og_title, seo.title),
    twitter_title: getTagValue(seo.twitter_title, seo.og_title, seo.title),
    "twitter:description": getTagValue(seo.twitter_description, seo.og_description, seo.description),
    twitter_description: getTagValue(seo.twitter_description, seo.og_description, seo.description),
    "twitter:image": getTagValue(story?.content?.image?.filename, seo.twitter_image, seo.og_image) || `${config.SITE_URL}/kudosity-social.jpg`,
    twitter_image: getTagValue(story?.content?.image?.filename, seo.twitter_image, seo.og_image) || `${config.SITE_URL}/kudosity-social.jpg`,
  });

  Object.entries(tags).forEach(([key, value]) => {
    if (value) {
      if (["_uid", "plugin"].includes(key)) {
        return;
      } else if (["title", "description"].includes(key)) {
        metaTags.push({ name: key, content: value });
      } else {
        metaTags.push({ property: key, content: value });
      }
    }
  });

  const link: { rel: string; href: string }[] = [];

  if (story) {
    let slug = "";
    if (story?.path || story?.full_slug) {
      slug = story?.path ? story?.path : `/${story?.full_slug}`;
      link.push({
        rel: "canonical",
        href: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
      });
    }

    if (story?.content?.Noindex === true) {
      metaTags.push({
        name: "robots",
        content: "noindex, nofollow",
      });

      defineRouteRules({
        robots: false,
      });
    }

    useSeoMeta({
      title: tags.title || "",
      titleTemplate: '%s',
      description: tags.description || "",
      ogTitle: tags.og_title || "",
      ogDescription: tags.og_description || "",
      ogImage: tags.og_image || `${config.SITE_URL}/kudosity-social.jpg`,
      twitterTitle: tags.twitter_title || "",
      twitterDescription: tags.twitter_description || "",
      twitterImage: tags.twitter_image || `${config.SITE_URL}/kudosity-social.jpg`,
      twitterCard: "summary_large_image",
      articleModifiedTime: story?.published_at,
    });

    useSchemaOrg([
      defineOrganization({
        name: "kudosity.com",
        alternateName: "kudosity",
        url: "https://kudosity.com/",
        logo: "https://a-us.storyblok.com/f/1001817/218x48/6e4aa31d6c/kudosity.svg",
        sameAs: ["https://www.facebook.com/kudosity.co", "https://www.linkedin.com/company/kudosity-co/", "https://www.youtube.com/@kudosity"],
      }),
      defineLocalBusiness({
        name: "kudosity.com",
        image: "https://a-us.storyblok.com/f/1001817/218x48/6e4aa31d6c/kudosity.svg",
        logo: `${config.SITE_URL}/kudosity-social.jpg`,
        "@id": "",
        alternateName: "kudosity",
        url: "https://kudosity.com/",
        telephone: "+61 1300 012 014",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Level 10, 60 Carrington Street",
          addressLocality: "Sydney",
          addressRegion: "NSW",
          postalCode: "2000",
          addressCountry: "AU",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
          opens: "00:00",
          closes: "23:59",
        },
        sameAs: ["https://www.facebook.com/kudosity.co", "https://www.linkedin.com/company/kudosity-co/", "https://www.youtube.com/@kudosity"],
      }),
    ]);

    if (["article", "guide"].includes(story?.content?.component)) {
      useSchemaOrg([
        defineArticle({
          "@type": "Article",
          headline: tags?.title,
          description: tags?.description,
          image: story?.content?.image?.filename || `${config.SITE_URL}/kudosity-social.jpg`,
          datePublished: new Date(story?.first_published_at).toISOString(),
          dateModified: new Date(story?.published_at).toISOString(),
        }),
      ]);

      const categories = Array.from(new Set([story?.content?.category, ...(story?.content?.categories?.value || [])].filter(Boolean)));

      if (categories.length > 0) {
        metaTags.push({
          property: "article:section",
          content: story?.content?.category || categories.join(", "),
        });
      }

      const keywords = Array.from(new Set([story?.content?.category, ...categories, ...(story?.content?.tags?.value || [])].filter(Boolean)));

      if (keywords.length > 0) {
        keywords.forEach((k: string) => {
          metaTags.push({
            property: "article:tag",
            content: k.trim(),
          });
        });
      }
    }

    if (["event"].includes(story?.content?.component)) {
      const eventDate = new Date(story?.content?.date || story?.published_at).toISOString();
      const event = {
        name: tags?.title,
        location: [
          {
            "@type": "VirtualLocation",
            url: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          },
        ],
        image: [tags["og:image"]],
        organizer: {
          name: "Kudosity",
          url: "https://kudosity.com",
        },
        eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        description: tags?.description,
        offers: {
          "@type": "Offer",
          url: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          price: "0",
          priceCurrency: "AUD",
          availability: "https://schema.org/InStock",
          validFrom: eventDate,
        },
        startDate: eventDate,
        endDate: eventDate,
        recordedIn: {
          "@type": "VideoObject",
          name: tags?.title,
          thumbnailUrl: tags["og:image"],
          contentUrl: `${config.SITE_URL}${slug}`.replace(/\/$/, ""),
          description: tags?.description,
          uploadDate: eventDate,
        },
      };

      if (story?.content?.speakers?.length > 0) {
        event.performer = story?.content?.speakers?.map((s: Object) => {
          return {
            "@type": "Person",
            name: s.name,
            jobTitle: s.citation,
            worksFor: {
              "@type": "Organization",
              name: s.company,
            },
          };
        });
      }

      useSchemaOrg([defineEvent(event)]);
    }
    if (["customer-story", "whitepaper"].includes(story?.content?.component)) {
      useSchemaOrg([
        defineArticle({
          "@type": "Report",
          headline: tags?.title,
          description: tags?.description,
          image: story?.content?.image?.filename || `${config.SITE_URL}/kudosity-social.jpg`,
          datePublished: new Date(story?.first_published_at).toISOString(),
          dateModified: new Date(story?.published_at).toISOString(),
        }),
      ]);
    }

    // Generate BreadCrumbs
    const paths = `@/${story?.full_slug}`?.replace(/\/+$/, "")?.split("/");
    const crumbs = paths.map((path, i) => {
      let name = i > 0 ? textTitleCase(path) : "Kudosity";

      if (i + 1 === paths?.length) return { name: tags.title };

      const item = (path.length > 0 ? paths?.slice(0, i + 1).join("/") : "/").replace("@", config.SITE_URL);

      return { name, item };
    });

    if (crumbs.length > 1) {
      crumbs.splice(0, 1); // Remove first
      crumbs.splice(-1, 1); // Remove last
      
      if (crumbs.length > 0) {
        useSchemaOrg([
          defineBreadcrumb({
            itemListElement: crumbs,
          }),
        ]);
      }
      
    }
  }

  if (metaTags.length > 0) {
    useHead(() => {
      return { title: tags.title, meta: metaTags, link };
    });
  }
}
